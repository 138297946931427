:root {
  --born2win-button-color: #66387C;
  --born2win-button-text-color: #ffffff;
  --born2win-button-green: rgb(0, 149, 162);
  --born2win-button-orange: #FCB414;
}

.App {
  overflow-x: hidden;
  text-align: center;
  direction: rtl;
  width: 100vw;
}



.header-logo {
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  width: 220px;
}

.icon-btn {
  border: none;
  background-color: transparent;
  width: 20px;
  height: 20px;
  color: var(--born2win-button-color);
}

.icon-btn-l {
  border: none;
  background-color: transparent;
  width: 60px;
  height: 60px;

}

.icon-btn-l .pi {
  font-size: 30px;
}


.icon-btn-withLabel {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  /* Add space between the icon and the label */
}

.red-dot {
  position: absolute;
  left: 5px;
  top: 5px;
  background-color: var(--born2win-button-color);
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.one-line-icon {
  top: 10px;
  display: flex;
  position: absolute;
  height: 100px;
  width: 100px;
  border-radius: 50%;

  justify-content: center;
  align-items: center;
  background-color: #F6F6F6;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}

.settingMenuItem {
  text-align: right;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.btn-on-the-right {
  position: absolute;
  left: 0;
}

.one-line-cancelled {
  position: absolute;
  top: 50px;
  right:150px;
  color: red;
  font-size: x-large;
  font-weight: bold;
  transform: rotate(-30deg);
}


/* Flip the arrows for hebrew experience of the Calendar control */
.p-calendar .p-datepicker .p-datepicker-prev {
  transform: rotate(180deg);
}

.p-calendar .p-datepicker .p-datepicker-next {
  transform: rotate(180deg);
}

.img-header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.standalone-dynamic-host {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  min-width: 800px;

}

.standalone-card {
  max-width: 700px;
  width: 700px;
  padding: 50px;

  border-radius: 10px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);

}

.clickable-span {
  color: #007bff;
  /* Change color to typical link color */
  text-decoration: underline;
  /* Add underline like links */
  cursor: pointer;
  /* Change cursor to pointer */
}

.clickable-span:hover {
  color: #0056b3;
  /* Darken the color on hover */
  text-decoration: none;
  /* Optionally remove underline on hover */
}

/* styles.css */
.select-button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 5px;
  flex-wrap: wrap;
}

.select-button-item {
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-weight: bold;
  color: var(--born2win-button-text-color);
}

.select-button-item:not(.p-highlight) {
  background-color: lightgray;

}

.select-button-item.p-highlight {
  /* background-color: #2196F3; */
  background-color: var(--born2win-button-green);
}


/* Born2Win theme */
/* custom-theme.css */
.p-button {
  background-color: var(--born2win-button-color) !important;
  border-color: var(--born2win-button-color) !important;
  color: var(--born2win-button-text-color);
  margin: 3px;
}

.p-button:hover {
  background-color: var(--born2win-button-color) !important;
  border-color: var(--born2win-button-color) !important;
}

/* Style for the selected tab */
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-title {
  color: var(--born2win-button-green) !important;
}

/* Style for the underline (border) of the selected tab */
.p-tabview-selected .p-tabview-nav-link {
  border-bottom: 2px solid var(--born2win-button-green) !important;
}

.settings-btn,
.settings-btn:hover {
  background-color: transparent !important;
  border: none;
  color: black;

}

.settings-btn>span {
  font-size: 25px;
}

.custom-spinner .p-progress-spinner {
  stroke: var(--born2win-button-orange);
  /* The main color of the spinner */
}

.custom-spinner .p-progress-spinner path {
  stroke: var(--born2win-button-orange);
  /* The color of the circular path */
}

.p-progressbar-value {
  background-color: var(--born2win-button-color);
}

.range-calender>input {
  font-size: 12px;
}


.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.round-button {
  min-width: 38px;
  max-width: 38px;
  min-height: 38px;
  max-height: 38px;
}
