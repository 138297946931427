.contact-edit {
    margin: 2px;
}

.contact-edit  .p-field {
    padding: 5px;
}

.mandatory-field {
    color: red; 
    vertical-align: super; 
    font-size:0.8em; 
    margin-right: 2px;
}