.notification-container {
    height: 120px;
    /* background-color: #f0f8ff; */
    background-color: rgba(252, 223, 72, 0.3);
    /* Light blue background */
    /* border-radius: 10px; */
    /* padding: 20px; */
    text-align: center;
    position: relative;
    /* margin: 20px 0; */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.notification-content {
    position: relative;
}

.notification-text {
    font-size: 17px;
    color: #333;
}


.notification-button {
    position: relative;
    z-index: 1;
    margin-left: 10px;
}

.notification-button .p-button-icon {
    font-size: 24px;
    /* Adjust size as needed */
}

.arrow-animation {
    font-size: 2rem;
    /* Adjust size as needed */
    color: var(--born2win-button-color);
    /* Color of the arrow */
    animation: slideLeftToRight 1s infinite;
}

@keyframes slideLeftToRight {

    0%,
    100% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(10px);
    }
}

/* weeks slider*/
/* Container for the slider */
.slider-container {
    position: relative;
    direction: rtl;
    width: 100%;
    height: 60px;
    /* Adjust height as needed */
    padding: 10px 0;
    /* Adds space for the marks below the slider */
}
.slider {
    display: flex;
    flex-direction: row;
    justify-content: center;

}



/* Ensure the marks are evenly distributed along the slider */
.react-slider .tick-mark {
    position: absolute;
    bottom: -25px;
    /* Move the mark below the slider */
    /* transform: translateX(-50%); */
    /* Center the mark under the thumb */
    font-size: 12px;
    text-align: center;
    color: black;
}


/* Style the slider */
.react-slider {
    position: relative;
    direction: rtl;
    width: 100%;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
}

/* Style for the thumb */
.react-slider .thumb {
    top: -5px;
    height: 24px;
    width: 24px;
    background-color: var(--born2win-button-color);
    /* border: 2px solid #007bff; */
    border-radius: 50%;
    cursor: grab;
    position: absolute;
}

/* Style for the track (selected range) */
.react-slider .track {
    background-color: #007bff;
    height: 100%;
    border-radius: 5px;
    position: absolute;
}



/* Marks (the numbers and labels along the slider) */
.tick-mark {
    position: absolute;
    top: -20px;
    /* Move the mark below the slider */
    font-size: 12px;
    color: black;
    text-align: center;
    width: 20px;
    /* Center the marks under the slider */
}

/* Style for today's mark */
.today-mark {
    font-weight: bold;
    color: var(--born2win-button-green);
}

/* Style for the selected track (the line between the thumbs) */
.react-slider .track-1 {
    background-color: var(--born2win-button-green);
    /* Selected track color */
}

/* Style for the non-selected track (before the first thumb or after the second thumb) */
.react-slider .track-0,
.react-slider .track-2 {
    background-color: #e0e0e0;
    /* Unselected track color */
}

.range-text {
    margin-top: 2px;
}