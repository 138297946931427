.family-demand-details {
    direction: rtl;
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
}

.city-chip {
    width: 90%;
    text-align: start;
    padding: 10px;
    font-weight: bold;
    background-color: gainsboro;

}

.family-chip {
    display: flex;
    flex-direction: row;
    width: 90%;
    text-align: start;
    padding: 5px;
    background-color: white;
}

.family-chip>label {
    width: 85px;
    margin-right: 10px;
    margin-left: 15px;
}

.family-details-link {
    width: 85px;
    margin-right: 10px;
    margin-left: 15px;
}

.available-date-host {
    display: flex;
    flex-direction: column;
    border-left: solid 1px gray;
    margin-left: 5px;
    padding-left: 5px;
}

.available-date {
    text-align: center;
}

.available-date-weekdays {
    text-align: start;
    font-size: 12px
}