
.carousel-container {
    position: relative;
    margin-top: 5px;
  }
  
  .carousel-indicators-container {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .p-carousel-indicators {
    display: inline-flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .p-carousel-indicator {
    background-color: lightgray;
    border-radius: 50%;
    height: 12px;
    width: 12px;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .p-carousel-indicator.p-highlight {
    background-color: blue;
  }