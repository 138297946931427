/* Styles for the registration component */
.registration-component {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.registration-scroller-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

}

.mode-nav-button-host {
    position: absolute;
    top: -10px;
    right: 0px;
}


.family-additional-header {
    font-size: 22px;
    color: var(--born2win-button-color);
    font-weight: bold;
    border: 1px solid;
    margin-left: 100px;
    margin-right: 100px;
}

.standalone-text-desc {
    font-family: Roboto, Arial, sans-serif;
    font-size: 15px;
    font-weight: 700;
}

.standalone-text-title {
    font-family: Roboto, Arial, sans-serif;
    font-size: 20px;
    font-weight: 700;
    margin-top: 15px;
    width: 90%;
    text-align: right;
}

.registration-scroller-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    /* width: calc(100% - 18px); */
}

.family-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    gap: 1rem;
}

.available-day {
    background-color: #1A92A2;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

/* Remove background and border specifically for today's date cell */
/* Target the td element for today's date and remove background and border */
.p-datepicker-calendar td.p-datepicker-today {
    background-color: transparent;
    border-color: transparent;
}

/* Target the span inside the td element to reset its style */
.p-datepicker-calendar td.p-datepicker-today span {
    background-color: transparent;
    color: inherit;
}

.selected-day {
    border: orange 4px solid;
}

.alergies {
    font-weight: bold;
    color: #A94442;
}

.calendar-container {
    position: relative;
}

.watermarked-no-dates .p-datepicker-calendar {
    position: relative;
}

.watermarked-no-dates::before {
    content: "אין תאריכים פנויים בחודש זה";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    pointer-events: none;
}

.watermarked-no-dates td,
.watermarked-no-dates th {
    position: relative;
    z-index: 2;
}

.standalone-title {
    color: #1992a2;
    font-weight: bolder;
    font-size: 20px;
}

.p-datepicker-prev,
.p-datepicker-next {
    display: none;
}

/* Hide month and year dropdowns */
.p-datepicker-month,
.p-datepicker-year {
    display: none;
}

.p-datepicker-header {
    display: none;
}


.select-month-badge {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: -10px;
    top: -10px;
    height: 100%;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    background-color: var(--born2win-button-color);
    color: var(--born2win-button-text-color);
    font-size: 15px;
    font-weight: 600;
}

.p-multiselect {
    flex-direction: row-reverse;
}

.p-multiselect-checkbox {
    margin-left: 7px;
}

.p-multiselect-header,
.p-multiselect-items-wrapper {
    direction: rtl;

}