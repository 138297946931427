.channel-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: rgb(95, 176, 108); */
    /* background-color:  rgba(252, 223, 72, 1); */
    background-color: var(--born2win-button-orange);
    color: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    box-shadow: 10px;
}

.channel-badge {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 20px;
    top: 40px;
    height: 100%;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: var(--born2win-button-color);
    color: var(--born2win-button-text-color);
    font-size: 20px;
    font-weight: 600;
}

.back-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 50px;
    height: 50px;
}