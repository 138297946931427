
.msg-badge {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 16px;
    background-color: var(--born2win-button-color);
    color: var(--born2win-button-text-color);

  }


.settings-title {
    margin-bottom: 20px;
    color: #333;
    text-align: center;
    font-size: 24px;
    border-bottom: 2px solid var(--born2win-button-color); /* Underline for the title */
    padding-bottom: 10px;
}

.header-loading {
    position: absolute;
    width:45px;
    height:45px;
    left: 70px;
}

.settings-menu {
    margin-top: 20px;
    direction: rtl; /* Set the direction to right-to-left */
}

.p-panelmenu .p-panelmenu-header-link {
    padding: 10px;
    font-size: 18px;
    color: #333;
    transition: color 0.3s;
    justify-content: flex-end; /* Aligns content to the right */
    display: flex;
    flex-direction: row-reverse;
    outline: none; /* Remove outline when focused */
}

.p-menuitem-text {
    width: 100%;
}


.p-panelmenu .p-panelmenu-header-link:focus,
.p-panelmenu .p-panelmenu-header-link:active {
    background-color: transparent !important;; /* Remove background on click */
    color: #333; /* Keep the original text color */
    outline: none; /* Remove outline when focused */
}

.p-panelmenu .p-panelmenu-header-link:hover {
    color: red;
    background-color: transparent !important;; /* Remove background on click */
}

.p-panelmenu .p-panelmenu-header-link .pi {
    margin-left: 10px; /* Move icon to the left of the text */
    font-size: 20px;
    
}

.settings-menu .p-panelmenu .p-panelmenu-header .p-panelmenu-header-link,
.settings-menu .p-panelmenu .p-panelmenu-content .p-menuitem-link {
    text-align: right; /* Align text to the right */
    direction: rtl; /* Ensure the text direction is right-to-left */
}

.settings-menu .p-panelmenu .p-menuitem .p-menuitem-icon {
    margin-left: auto; /* Push icons to the left of the text */
}

.impersonate-name {
    position: absolute;
    bottom:0;
    left:0;
    right:0;
}