
.three-dot-menu {
  position: absolute;
  right: 0;
  height: 40px;
  border: none;
  background-color: transparent;
  color: var(--born2win-button-color);
}
.three-dot-menu > span{
  font-size: 23px;
}


.no-messages {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  font-size: 35px;
}